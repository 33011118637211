@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #272727;
}

.debug {
    border: 1px solid gray;
    display: inline-block;
}
